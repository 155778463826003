<template>

  <el-form :class="type == 'view' ? 'form-read-only' : ''" v-loading="loadingData" :model="objData" :rules="rules"
    ref="formRef">
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-9 pb-0">

        <div class="row g-9 mb-7">
          <div class="col-md-6 fv-row" v-for="language in languages">
            <label class="required fs-6 fw-bold mb-2">Name ({{ language }})</label>
            <el-form-item :prop="'name.' + language" :rules="rules['field']">
              <el-input v-model="objData.name[language]" :placeholder="`Name(${language})`" />
            </el-form-item>
          </div>
        </div>

        <div class="row g-9 mb-7">
          <div class="col-md-4 fv-row">
            <label class="fs-6 fw-bold mb-2">Is Active</label>
            <el-form-item>
              <el-switch v-model="objData.is_active" />
            </el-form-item>
          </div>
        </div>
      </div>
    </div>

    <div class="row g-9 mb-7">
      <div class="col-md-4 fv-row">

      </div>
      <div class="col-md-4 fv-row">
        <button v-if="type != 'view'" @click="submitForm()" :data-kt-indicator="loading ? 'on' : null"
          class="btn btn-lg btn-primary full-width" type="button" :disabled="loading">
          <span v-if="!loading" class="indicator-label">
            Submit
          </span>
          <span v-if="loading" class="indicator-progress">
            Please wait...
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        </button>
      </div>
      <div class="col-md-4 fv-row">

      </div>
    </div>

  </el-form>

</template>

<script>
import { defineComponent, ref, toRefs, watch } from "vue";
import { getIllustrationsPath } from "@/core/helpers/assets";
import { useRoute } from 'vue-router'
import ApiAxiosService from "@/core/services/ApiAxiosService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { handleError, handleSuccess } from "@/core/helpers/response";
import router from "@/router";
import { APIs } from "@/store/enums/ApiEnums";
import { getCities } from "@/core/helpers/remote_data";
import ImageComp from "@/components/admin/general/ImageComp";
import { useRouter } from 'vue-router'

export default defineComponent({
  name: "unit-comp",
  components: { ImageComp },
  props: ['type'],
  setup(props) {
    const router = useRouter();
    const { type } = toRefs(props);
    const route = useRoute();
    const formRef = ref(null);
    const objData = ref({});
    const loading = ref(null);
    const loadingData = ref(false);
    const multiLanguageFields = ref(['name']);
    const isIndeterminate = ref(true)
    const languages = ref(['en', 'ar']);


    objData.value = {
      name: {},
      description: {},
      is_active: true,
    };

    const rules = ref({
      field: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
    });
    const initData = async () => {
      let response = null;
      loadingData.value = true;
      switch (props.type) {
        case 'create':
          try {
            response = await ApiAxiosService.get(APIs.UNIT.create);
            languages.value = response.data.data.languages;
          } catch (e) {
            handleError(e)
          }

          break;
        case 'edit':
          try {
            response = await ApiAxiosService.get(APIs.UNIT.edit(route.params.id));
            objData.value = response.data.data.unit;
            languages.value = response.data.data.languages;
          } catch (e) {
            handleError(e)
          }

          break;
        case 'view':
          try {
            response = await ApiAxiosService.get(APIs.UNIT.show(route.params.id));
            objData.value = response.data.data.unit;
            languages.value = response.data.data.languages;
          } catch (e) {
            handleError(e)
          }

          break;
      }
      loadingData.value = false;

    }

    const submitForm = () => {

      formRef.value.validate((valid) => {
        if (valid) {
          switch (props.type) {
            case 'create':
              storeResource();
              break;
            case 'edit':
              updateResource();
              break;
          }

        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    }
    const storeResource = () => {
      var formData = new FormData();
      Object.keys(objData.value).forEach(function (key) {
        formData.append(key, multiLanguageFields.value.includes(key) ? JSON.stringify(objData.value[key]) : objData.value[key]);
      });
      loading.value = true;
      ApiAxiosService.post(APIs.UNIT.store, formData).then(function (res) {
        loading.value = null;
        handleSuccess(res);
        setTimeout(function () {
          router.push({ name: 'units-list' })
        }, 1000)
      }).catch(function (err) {
        loading.value = null;
        handleError(err);
      });
    }
    const updateResource = () => {
      var formData = new FormData();
      Object.keys(objData.value).forEach(function (key) {
        formData.append(key, multiLanguageFields.value.includes(key) ? JSON.stringify(objData.value[key]) : objData.value[key]);
      });
      loading.value = true;
      formData.append('_method', 'put');
      ApiAxiosService.post(APIs.UNIT.update(route.params.id), formData).then(function (res) {
        loading.value = null;
        handleSuccess(res);
        setTimeout(function () {
          router.push({ name: 'units-list' })
        }, 1000)
      }).catch(function (err) {
        loading.value = null;
        handleError(err);
      });
    }


    initData();


    return {
      getIllustrationsPath,
      formRef,
      objData,
      loading,
      loadingData,
      rules,
      initData,
      submitForm,
      storeResource,
      updateResource,
      type,
      isIndeterminate,
      languages
    };
  },

});
</script>
